import logo from './logo.svg';
import './App.css';



function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Weebdata...</h1>
        <p>We're working on it...brb, you degenerate.</p>
        <p>Latest Update: It's taking a little longer than expected ...</p>
      </header>
    </div>
  );
}

export default App;
